<template>
    <div id="menu">
        <img src="/img/menusaze.png" alt="menu" class="menu-icon" v-on:click="showMenu()">
        <div class="menu-base" :class="direction">
        <div class="menu-close">
            <img src="/img/closesaze.png" v-on:click="hideMenu()" class="close" />
        </div>
        <div class="container menu-container">
            <div class="row  justify-content-center h-100">
                <div class="col-lg-10 col-10 d-flex align-items-center justify-content-center">
                  <div class="col-3">
                    <img class="menu-logo" :src="'/img/'+cat.logo" alt="">
                  </div>
                </div>
                <div class="col-10">
                    <ul class="menu" :class="border">
                        <li v-on:click="hideMenu()" class="menu-items" :class="borderItem+' '+menu.hasChild" v-for="menu in menus"
                         :key="menu.id">
                         <ul class="childs borderRight" :class="border" v-if="menu.hasChild">
                           <li class="child-items" :class="borderItem" v-for="me in menu.children" :key="me.key">
                             <router-link :to="{ name:menu.link, params: { language: $i18n.locale, category: category},props:{category:category}}">
                             {{ me.name }}
                           </router-link>
                         </li>
                         </ul>
                            <router-link :to="{ name:menu.link, params: { language: $i18n.locale, category: category},props:menu.props}">
                              {{ menu.title }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import httpClient from '@/services/http.service';
    export default {
        name:'MenuBuilding',
        data:()=>{
            return{
                menuIcon : '18.png',
                closeIcon : '29.png',
                border : 'building-b',
                borderItem : 'building-90',
                parentCategory:'null',
                direction:'menu-rtl',
                error:'null',
                category:'building',
                menus:'null',
                cat:{
                    id:1,
                    name:'building',
                    logo:'Untitled-1-02.png'
                }
            }

        },
        methods:{
            showMenu: function(){
                this.$anime({
                    targets: ".menu-base",
                    width:"100%",
                    duration:2100,
                    opacity:1,
                    easing: 'easeInOutExpo'
                    //easing : 'easeOutExpo'
                })
            },
            hideMenu: function(){
                this.$anime({
                    targets: ".menu-base",
                    width:"0",
                    opacity:0,
                    duration:2100,
                    easing: 'easeInOutExpo'
                })
            }
        },
        async mounted(){
          try {
                const {status , data} = await httpClient.get('/fa/categories',{params:{
                  title:this.$route.params.category,
                  expand:'childs,'
                }})
                if (status == 200) {
                  if(data[0].parentCategory == null){
                    this.category = data[0].title;
                  }else{
                    this.parentCategory = data[0].parentCategory.title;
                  }
                  
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
              this.menus = [
                  {
                      id:1,
                      title:this.$t("menu.home"),
                      link:'Home'
                  },
                  {
                      id:2,
                      title:this.$t("menu.about"),
                      link:'About'
                  },
                  {
                      id:3,
                      title:this.$t("menu.projects"),
                      link:'Projects'
                  },
                  {
                      id:4,
                      title:this.$t("menu.contact"),
                      link:'Contact'
                  },
                  {
                      id:5,
                      title:this.$t("menu.ads"),
                      link:'Ads',
                      hasChild:'hasChild',
                      children:[
                        {
                          id:1,
                          name:this.$t("menu.sale"),
                        },
                        {
                          id:2,
                          name:this.$t("menu.tenders"),
                        },
                        {
                          id:3,
                          name:this.$t("menu.cooperation"),
                        }
                      ]
                  },
                  {
                      id:6,
                      title:this.$t("menu.tomorrow"),
                      link:'Tomorrow',
                      props:{category:this.category}
                  },
                ];
            
            if (this.$route.params.language == 'fa') {
            this.direction = 'menu-rtl';
          }else{
            this.direction = 'menu-ltr';
          }
                
        },
    }
</script>

<style lang="scss" scoped>
#menu{
    direction: rtl;
    display: block;

}
.menu-icon{
    width: 2.25em;
    height: 3em;
    direction: rtl;
    position: fixed;
    top: 20px;
    left: 25px;
    opacity: 0.7;
    display: block;
    z-index: 200;
    cursor: pointer;
}
.menu-icon:hover{
  opacity: 1;
}
.menu-rtl{
  direction: rtl;
  text-align: right;
  font-size: 24px;
}
.menu-ltr{
  direction: ltr;
  text-align: left;
  font-size: 18px;
}
.menu-base{
    position: fixed;
    top:0;
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(17,31,41,1) 100%);
    z-index: 200;
    overflow: hidden;
}
.close{
    position: absolute;
    display: block;
    left: 25px;
    top: 20px;
    padding: 10px;
    cursor: default;
    width: 3rem;
    height: 3rem;
}
.menu-logo{
    width: 100%;
}
.container{
    height: 100%;
}
.menu{
    list-style: none;
    border-top: 3px solid;
    padding-right:25px ;

}
.building-b{

  border-image-source: linear-gradient(45deg, #000000 0%,#ff0000 100%);
  border-image-slice: 1;
}
.building-90{

  border-image-source: linear-gradient(90deg, #000000 0%,#ff0000 100%);
  border-image-slice: 1;
}
.menu-items{
    color: #fff;
    font-weight: lighter;
    text-transform: uppercase;
    margin-top: 1em;
    padding-bottom: 0.5em;
    //margin-right: 7%;
    cursor: pointer;
    display: inline-block;
}
.menu-items:nth-child(n){
  margin-right: 7%;
}
.menu-items:hover{
    border-bottom: 2px solid;
}
a{
    color:#fff
}
a:hover{
    text-decoration: none;
}
.childs{
  display: none;
  position: absolute;
  top: 2.5em;
  padding-right: 10px;
  margin-right: -2px;
}
.borderRight{
  border-right: 2px solid;
}
.child-items{
  font-size: 15px;
  padding-top: 1.5em;
  padding-right: 6px;
}
.child-items:first-child{
  padding-top: 2.7em;
}
.child-items:last-child{
  margin-bottom: 1em;
}
.child-items::marker{
  content: '';
}
.child-items:hover{
  border-bottom: 2px solid;
}
.menu-items:hover .childs{
  display: block;
}
.hasChild:hover{
  border-bottom: none;
  border-right: 2px solid;
}
.hasChild a{
  margin-right: 5px;
}
@media (max-width:600px){
    .menu-items{
        font-size: 12px;
    }
}
</style>
